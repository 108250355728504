import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[(_vm.selectedBlogPost && _vm.selectedBlogPost.title)?_c(VRow,[_c(VCol,{attrs:{"xl":"6","lg":"8","sm":"10","offset-xl":"3","offset-lg":"2","offset-sm":"1"}},[_c('p',{staticClass:"text-h1"},[_vm._v(_vm._s(_vm.selectedBlogPost.title))]),(_vm.selectedBlogPost && _vm.selectedBlogPost.subtitle)?_c('p',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.selectedBlogPost.subtitle))]):_vm._e()])],1):_vm._e(),(_vm.selectedBlogPost && _vm.selectedBlogPost.image)?_c(VRow,[_c(VCol,{attrs:{"xl":"6","lg":"8","sm":"10","offset-xl":"3","offset-lg":"2","offset-sm":"1"}},[_c(VImg,{attrs:{"src":_vm.selectedBlogPost.image}})],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"xl":"2","offset-xl":"3","lg":"5","offset-lg":"2","offset-md":"1","offset-sm":"1","cols":"5"}},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.selectedBlogPost.formattedCreatedDate))])]),_c(VCol,{attrs:{"xl":"4","lg":"3","md":"5","sm":"5"}},[_c('p',{staticClass:"text-right my-0"},[_vm._v("By: "+_vm._s(_vm.selectedBlogPost.author))])])],1),_c(VRow,[_c(VCol,{attrs:{"xl":"6","lg":"8","sm":"10","offset-xl":"3","offset-lg":"2","offset-sm":"1"}},[_c(VDivider),_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.selectedBlogPost.tags),function(tag){return _c(VChip,{key:tag.id,staticClass:"text-uppercase",on:{"click":function($event){return _vm.loadTagPage(tag)}}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"xl":"6","lg":"8","sm":"10","offset-xl":"3","offset-lg":"2","offset-sm":"1"}},[_c(VSheet,{staticClass:"blog-post-sheet",attrs:{"color":"white","elevation":"1","outlined":"","tile":""},domProps:{"innerHTML":_vm._s(_vm.selectedBlogPost && _vm.selectedBlogPost.content)}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }